<template>
  <div style="overflow: hidden; position: relative;">
    <el-form :inline="true" :model="smsSche" class="demo-form-inline" ref="smsSche">
      <el-form-item prop="pmaal004">
        <el-input v-model="smsSche.jobName" placeholder="任务名称" clearable></el-input>
      </el-form-item>
      <el-form-item style="margin-right: 0; white-space: nowrap;">
        <el-button type="primary" plain @click="onSearch('search')">查询</el-button>
        <el-button type="primary" plain @click="resetForm('smsSche')">重置</el-button>
        <el-button type="primary" @click="addMethod()">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" style="width: 100%;" max-height="654" border v-loading="loading">
      <el-table-column fixed prop="jobName" label="任务名称"></el-table-column>
      <el-table-column prop="jobClass" label="触发方法"></el-table-column>
      <el-table-column prop="cronExpression" label="时间格式"></el-table-column>
      <el-table-column prop="status" label="启用状态" width="120">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 1" type="success">启动</el-tag>
          <el-tag v-else-if="scope.row.status === 0" type="info">停止</el-tag>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button @click.native.prevent="changeRow(scope.$index, tableData)" icon="el-icon-edit" type="text">
            修改
          </el-button>
          <el-button @click.native.prevent="deleteRow(scope.$index, tableData)" icon="el-icon-delete" type="text">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="rows"
      layout="total, prev, pager, next, jumper" :total="total">
    </el-pagination>

    <el-dialog :title="(flag === 'add' ? '新增':'修改')+'详情页'" :visible.sync="dialogVisible" width="35%" top="18vh"
      @close="handleClose" :modal-append-to-body="false" :append-to-body="true">
      <div class="cont-box">
        <el-form :inline="true" :model="smsImport" class="my-form" ref="smsImport" :rules="rule01">
          <el-form-item prop="jobName" label="任务名称">
            <el-input v-model="smsImport.jobName" placeholder="任务名称" clearable></el-input>
          </el-form-item>
          <el-form-item prop="jobClass" label="触发方法">
            <el-input v-model="smsImport.jobClass" placeholder="触发方法" clearable></el-input>
          </el-form-item>
          <el-form-item prop="cronExpression" label="时间格式">
            <el-input v-model="smsImport.cronExpression" placeholder="时间格式" clearable></el-input>
          </el-form-item>
          <el-form-item prop="status" label="启用状态">
            <template>
              <el-select v-model="smsImport.status" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-form-item>
          <el-form-item prop="limits" label="发送对象">
            <template>
              <el-select v-model="smsImport.limits" placeholder="请选择">
                <el-option v-for="item in limits" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="subCont('smsImport')" v-if="hasAuth('sys:serve:cpSub')">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import qs from 'qs'

  export default {
    name: 'Schedule',
    data() {
      return {
        loading: true,
        dialogVisible: false,
        drawer: false,
        smsSche: {
          jobName: '',
          status: ''
        },
        options: [{
          value: 1,
          label: '启动'
        }, {
          value: 0,
          label: '停止'
        }],
        limits: [{
          value: '1',
          label: '全员'
        }, {
          value: '0',
          label: '部分'
        }],
        smsImport: {
          jobName: '',
          jobClass: '',
          cronExpression: '',
          status: '',
          limits: ''
        },
        flag: 'add',
        tableData: [],
        curData: {},
        rule01: {
          jobName: [{
            required: true,
            message: '请输入',
            trigger: 'blur'
          }],
          jobClass: [{
            required: true,
            message: '请输入',
            trigger: 'blur'
          }],
          cronExpression: [{
            required: true,
            message: '请输入',
            trigger: 'blur'
          }],
          status: [{
            required: true,
            message: '请输入',
            trigger: 'blur'
          }],
          limits: [{
            required: true,
            message: '请输入',
            trigger: 'blur'
          }]
        },
        page: 1,
        rows: 10,
        total: null
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      getList(e) {
        this.loading = true
        if (e === 'search') {
          this.page = 1
        }
        this.$axios.post('/smsSchedule/findList', {
          current: this.page,
          size: this.rows,
          ...this.smsSche
        }).then(res => {
          this.loading = false
          this.tableData = res.data.data.records
          this.total = res.data.data.total
        }).catch(err => {
          this.loading = false
          this.$message.error('加载失败，请稍候再试。')
        })
      },
      deleteRow(index, rows) {
        var that = this
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.post('/smsSchedule/delete', {
            id: rows[index].id,
            jobName: rows[index].jobName
          }).then(res => {
            if (res.data.data) {
              rows.splice(index, 1);
              that.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getList()
            } else {
              that.$message({
                type: 'error',
                message: '删除失败!'
              });
            }
          }).catch(err => {
            this.loading = false
            this.$message.error('加载失败，请稍候再试。')
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      handleCurrentChange(val) {
        this.page = val
        this.getList()
      },
      addMethod() {
        this.smsImport = {}
        this.dialogVisible = true
        this.flag = 'add'
      },
      changeRow(index, data) {
        this.dialogVisible = true
        this.smsImport = JSON.parse(JSON.stringify(data[index]))
        this.flag = 'change'
      },
      onSearch(e) {
        this.getList(e)
      },
      subCont(formName) {
        var that = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.flag === 'add') {
              this.$axios.post('/smsSchedule/save', this.smsImport).then(res => {
                if (res.data.data > 0) {
                  that.dialogVisible = false
                  that.$message({
                    message: '添加成功',
                    type: 'success'
                  })
                  that.getList()
                } else if (res.data.data === -1) {
                  that.$message({
                    message: '时间格式输入有误！',
                    type: 'error'
                  })
                } else {
                  that.$message({
                    message: '保存失败！',
                    type: 'error'
                  })
                }
              })
            } else {
              this.$axios.post('/smsSchedule/update', this.smsImport).then(res => {
                if (res.data.data > 0) {
                  that.dialogVisible = false
                  that.$message({
                    message: '更新成功',
                    type: 'success'
                  })
                  that.getList()
                } else if (res.data.data === -1) {
                  that.$message({
                    message: '时间格式输入有误！',
                    type: 'error'
                  })
                } else {
                  that.$message({
                    message: '保存失败！',
                    type: 'error'
                  })
                }
              })
            }
          } else {
            return false;
          }
        })
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      handleClose() {
        this.resetForm('smsImport')
      }
    }
  }
</script>

<style scoped>
  .demo-form-inline {
    display: flex;
  }

  .el-table {
    box-sizing: border-box;
  }

  .drawer-cont {
    box-sizing: border-box;
    padding: 0 20px;
  }

  .el-tag {
    /* cursor: pointer; */
    padding: 0 24px;
  }

  .el-table>>>.cell .el-button {
    padding: 0;
  }

  /*  .el-dialog__wrapper {
    height: 100%;
  }
  .el-dialog__wrapper >>> .el-dialog {
    height: 80%;
    overflow-y: auto;
  } */
  .cont-box {
    padding: 0 10px;
    height: 210px;
    overflow-y: auto;
  }

  .list-cont {
    display: flex;
    flex-wrap: wrap;
    margin: 0 1em;
    padding: 14px 0;
  }

  .list-cont01 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 1em;
    padding: 14px 0;
  }

  .list-cont li {
    width: 25%;
    display: flex;
    padding: 6px 0;
    align-items: center;
  }

  .list-cont01>div {
    width: 25%;
    display: flex;
    padding: 6px 0;
    align-items: center;
    margin-bottom: 0;
  }

  .title {
    line-height: 34px;
    background: #f9f9f9;
    text-indent: 1em;
    color: #999999;
  }

  .list-cont li>label {
    white-space: nowrap;
  }

  .list-cont01>div>>>.el-form-item__label {
    white-space: nowrap;
  }

  .list-cont01>div>>>.el-form-item__content {
    flex: 1;
    margin: 0 1em 0 .5em;
  }

  .list-cont li>p {
    flex: 1;
    color: #323232;
    margin: 0 10px;
    min-height: 20px;
    border-bottom: 1px solid #dedede;
  }

  .list-cont li>.el-input,
  .list-cont li>.el-select,
  .list-cont li>.el-textarea {
    flex: 1;
    margin: 0 1em 0 .5em;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }

  .el-select {
    width: 100%;
  }

  .my-form {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
  }

  .my-form>.el-form-item {
    box-sizing: border-box;
    width: 50%;
    margin: 0;
    padding: 10px;
    display: flex;
  }

  .my-form>>>.el-form-item__label {
    white-space: nowrap;
  }
</style>
